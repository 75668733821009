
  import React from 'react';
  import { getWidgetWrapper } from '@wix/yoshi-flow-editor/runtime/esm/WidgetWrapper.js';
  import Widget from '/home/builduser/work/be92b78070518c73/packages/client/crypto-nft-purchase-dialog/src/components/PurchaseDialog/Widget/index.tsx';

  import {
    withStyles
  } from '@wix/native-components-infra';

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
import { createExperiments, createWidgetExperiments } from '@wix/yoshi-flow-editor/runtime/esm/experiments';
    

  
import { I18nextProvider } from '@wix/yoshi-flow-editor/i18n';


      import { PureExperimentsProvider } from '@wix/yoshi-flow-editor';
      var ExperimentsProvider = React.Fragment;
      

import { BILoggerProvider } from '@wix/yoshi-flow-editor/runtime/esm/react/BILogger/BILoggerProvider';

import { FedopsLoggerProvider } from '@wix/yoshi-flow-editor/runtime/esm/react/FedopsLoggerProvider';

import { HttpProvider } from '@wix/yoshi-flow-editor';

  import { TPAComponentsProvider } from 'wix-ui-tpa/TPAComponentsConfig';

var BaseUiEnvironmentProviderWrapper = React.Fragment;

var providers = {
  I18nextProvider,
  ExperimentsProvider,
  PureExperimentsProvider,
  BILoggerProvider,
  FedopsLoggerProvider,
  HttpProvider,
  TPAComponentsProvider,
  BaseUiEnvironmentProviderWrapper,
}


  import stylesParams from '/home/builduser/work/be92b78070518c73/packages/client/crypto-nft-purchase-dialog/src/components/PurchaseDialog/stylesParams.ts';

  var sentryConfig = {
      DSN: 'https://db570979e2d4436aacebd6bda6479858@sentry.wixpress.com/2835',
      id: 'db570979e2d4436aacebd6bda6479858',
      projectName: 'crypto-nft-purchase-dialog',
      teamName: 'ecom',
      errorMonitor: true,
    };

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/be92b78070518c73/packages/client/crypto-nft-purchase-dialog/src/assets/locales/messages_en.json","availableLanguages":["en","uk"]};

  var UserComponent = getWidgetWrapper({
      initI18n,
      withStylesHoc: withStyles,
      createExperiments,
      createWidgetExperiments,
      providers,
    }, Widget, {
    sentryConfig,
    translationsConfig,
    stylesParams,
    componentId: '53f0bfee-f390-4c3f-8cd0-68b0cbc80e00',
    name: 'PurchaseDialog',
    withErrorBoundary: false,
    localeDistPath: 'assets/locales',
  });

  
    import { hot } from '@wix/component-hot-loader';
    UserComponent = hot(module, UserComponent);
  

  const loadChunks = Widget.loadChunks;

  export default {
    
    component: UserComponent,
    loadChunks
  };
